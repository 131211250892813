import '@/assets/main.css';

import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import '@fortawesome/fontawesome-free/css/all.css';
// require('dotenv').config();
import store from './store'; // Import your Vuex store

// import store from "./store";
import vuetify from "./plugins/vuetify";
import VueTablerIcons from "vue-tabler-icons";
// import vueMaterialDesignIcons from "vue-material-design-icons";
// import { commonMixin } from "@/components/utilities/commonMixin.js";

const app = createApp(App)
router.beforeEach((to, from, next) => {
    const token = localStorage.getItem('token');
    if (to.meta.requiresAuth && !token) {
        next({ name: 'home' }); // Redirect to home if not logged in
    } else {
        next();
    }
});

app.use(router)
app.use(store);
// app.use(vueMaterialDesignIcons);
app.use(vuetify);
app.use(VueTablerIcons);
// app.mixin(commonMixin);
app.mount('#app')