// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

// Composables
import { createVuetify } from "vuetify";

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
    theme: {
        themes: {
            light: {
                colors: {
                    primary: "#272931",
                    primaryLight: "#3E4046",
                    primaryLighter: "#1F2128",
                    warning: "#FFB629",
                    white: "#FFFFFF",
                },
            },
        },
    },
});