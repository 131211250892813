import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/Home.vue'

const router = createRouter({
    history: createWebHistory(
        import.meta.env.BASE_URL),
    routes: [{
            path: '/',
            name: 'home',
            component: HomeView
        },
        {
            name: "AppLayout",
            path: "/Customer",
            component: () =>
                import ("@/layouts/AppLayout.vue"), // AppLayout wraps the child routes
            children: [{
                    name: "CustomerInfo",
                    path: "customer-info", // Nested under the layout
                    component: () =>
                        import ("../views/CustomerInfo.vue"),
                    meta: {
                        title: "Customer Info",
                    },
                },
                {
                    name: "VideoWalkAround",
                    path: "video-walkaround",
                    component: () =>
                        import ("../views/VideoWalkAround.vue"),
                    meta: {
                        title: "Video Walk Around",
                    },
                },
                {
                    name: "VideoWalkAroundData",
                    path: "video-walkaround-data",
                    component: () =>
                        import ("../views/VideoWalkAroundData.vue"),
                    meta: {
                        title: "Video Walk Around Data",
                    },
                },
                {
                    name: "VehicleHistory",
                    path: "vehicle-history",
                    component: () =>
                        import ("../views/VehicleHistory.vue"),
                    meta: {
                        title: "Vehicle History",
                    },
                },
                {
                    name: "VehicleHistoryData",
                    path: "vehicle-history-data",
                    component: () =>
                        import ("../views/VehicleHistoryData.vue"),
                    meta: {
                        title: "Vehicle History",
                    },
                },
                {
                    name: "UpcomingServices",
                    path: "upcoming-services",
                    component: () =>
                        import ("../views/UpcomingServices.vue"),
                    meta: {
                        title: "Upcoming Services",
                    },
                },
                {
                    name: "ContactUs",
                    path: "contact-us",
                    component: () =>
                        import ("../views/ContactUs.vue"),
                    meta: {
                        title: "Contact Us",
                    },
                },
            ],
        },
        {
            name: "PageNotFound",
            path: "/:pathMatch(.*)*",
            component: () =>
                import ("../views/PageNotFound.vue"),
            meta: { title: "Page Not Found" },
        },
    ]
});

// Define routes
// const routes = [{
//         path: '/',
//         name: 'home',
//         component: () =>
//             import ("../views/Home.vue"),
//         meta: {
//             title: "Customer Portal",
//         },
//     },
//     {
//         name: "AppLayout",
//         path: "/second",
//         component: () =>
//             import ("@/layouts/AppLayout.vue"), // AppLayout wraps the child routes
//         children: [{
//                 name: "CustomerInfo",
//                 path: "customer-info", // Nested under the layout
//                 component: () =>
//                     import ("../views/CustomerInfo.vue"),
//                 meta: {
//                     title: "Customer Info",
//                 },
//             },
//             {
//                 name: "VideoWalkAround",
//                 path: "video-walkaround",
//                 component: () =>
//                     import ("../views/VideoWalkAround.vue"),
//                 meta: {
//                     title: "Video Walk Around",
//                 },
//             },
//             {
//                 name: "VehicleHistory",
//                 path: "vehicle-history",
//                 component: () =>
//                     import ("../views/VehicleHistory.vue"),
//                 meta: {
//                     title: "Vehicle History",
//                 },
//             },
//             {
//                 name: "UpcomingServices",
//                 path: "upcoming-services",
//                 component: () =>
//                     import ("../views/UpcomingServices.vue"),
//                 meta: {
//                     title: "Upcoming Services",
//                 },
//             },
//             {
//                 name: "ContactUs",
//                 path: "contact-us",
//                 component: () =>
//                     import ("../views/ContactUs.vue"),
//                 meta: {
//                     title: "Contact Us",
//                 },
//             },
//         ],
//     },
//     {
//         name: "PageNotFound",
//         path: "/:pathMatch(.*)*",
//         component: () =>
//             import ("../views/PageNotFound.vue"),
//         meta: { title: "Page Not Found" },
//     },
// ];

// // Create router instance
// const router = createRouter({
//     history: createWebHistory(process.env.BASE_URL),
//     routes,
// });

// export default router;

export default router