

<template>
  <div class="dashboard  text-white h-screen min-h-100 bg-[url('@/assets/images/home/home-bg-bg.png')] bg-cover bg-center">
    <!-- Customer Card Section -->
    <div class="flex flex-col items-center pt-5 sm:pt-10 block md:hidden ">
      <img src="@/assets/images/home/top-home.png" alt="Profile Avatar" class="rounded-full object-cover w-40 sm:w-60" />
    </div>
    <div class="w-11/12 m-auto">
    <div class="ml-2 sm:ml-5">
    <h4 class="text-base md:text-xl items-start md:pt-10 block md:hidden">Welcome!</h4>
      <h2 class="text-lg sm:text-2xl font-bold block md:hidden">{{ displayName }} </h2>
    </div>
    <div class="customer-card flex justify-between items-center rounded-lg p-3 md:p-4 pe-0 main-color text-white h-4/6">
      <div class="profile flex flex-col md:items-center py-2 md:py-5 w-52 md:w-64 md:justify-center md:content-between md:text-center space-y-1 md:space-y-4 rounded-lg md:bg-black">
        <!-- Avatar -->
        <div class="avatar hidden md:block w-12 h-12 md:w-20 md:h-20 rounded-full md:bg-gray-600">
          <img src="@/assets/images/site/avatar-placeholder.png" alt="Profile Avatar" class="rounded-full object-cover w-full h-full" />
        </div>
          <h4 class="text-base md:text-xs  items-start md:pt-4 md:block hidden">Welcome!</h4>
          <h2 class="text-lg sm:text-2xl font-bold md:block hidden mt-1">{{ displayName }}</h2>
          <ul class="text-sm space-y-1 sm:content-between">
            <li class="content-between flex items-center sm:justify-between"><span>Make:</span> <span>{{ vehicle?.specification?.makeCode ? vehicle?.specification?.makeCode : vehicle?.specification?.make }}</span></li>
            <li class="content-between hidden sm:flex items-center sm:justify-between"><span>Model:</span> <span>{{ vehicle?.specification?.modelCode ? vehicle?.specification?.modelCode : vehicle?.specification?.model }}</span></li>
            <li class="content-between hidden sm:flex items-center sm:justify-between"><span>Year:</span> <span>{{ vehicle?.specification?.modelYear }}</span></li>
            <li class="content-between hidden sm:flex items-center sm:justify-between"><span>Color:</span> <span>{{ vehicle?.exteriorColor }}</span></li>
            <li class="content-between hidden sm:flex items-center sm:justify-between "><span class="mr-2">VIN: </span> <span>{{ vehicle?.identification?.vin }}</span></li>
          </ul>
      </div>
      <!-- Car Image Section -->
      <div class="car-image w-5/6 h-3/6 md:w-3/6 xl:w-3/5">
        <img src="@/assets/images/home/home-bg.png" alt="Car" class="rounded-lg shadow-lg" />
      </div>
    </div>
    </div>

    <!-- Action Buttons Section -->
    <div class="w-11/12 pl-2 sm:pl-7 m-auto my-1 sm:my-3 flex justify-between">
      <h3 class="text-xl font-semibold">Menu</h3>
    </div>
    <div class="actions grid grid-cols-2 sm:grid-cols-5 gap-2 sm:gap-6 w-11/12 m-auto">
      
      <router-link
        v-for="action in userInfo.actions"
        :key="action"
        :to="action.link"
        class="action-button bg-gray-800 hover:bg-gray-600 text-white rounded-lg py-3 md:py-4 px-3 md:px-6 flex flex-col items-center space-y-2 transition duration-200"
      >
        <img :src="action.img" :alt="action.alt" class="w-12 h-12 mb-2" />
        <span class="text-sm font-semibold">{{ action.text }}</span>
      </router-link>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed  } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import store from '@/store';
import axios from 'axios';
import userInfo from '@/data/userInfo';

// Reactive references
const customerInfo = ref({
  customerName: "Guest",
  vehicle: {
        specification: {
            makeCode: "Toyota",
            make: "Toyota",
            modelCode: "Camry",
            model: "Camry",
            modelYear: 2019,
        },
        identification: {
            vin: "12356589789897",
        },
        exteriorColor: "white",
    }, // Default name
});
const message = ref(''); // Error message display
// Vue Router hooks
const route = useRoute();
const router = useRouter();

onMounted(async () => {
  const token = route.query.token ? route.query.token : localStorage.getItem('token'); // Extract token from URL query
  // console.log("Received token:", localStorage.getItem('token'));

  if (!token) {
    message.value = 'Invalid login link.';
    return;
  }
  
  try {
    // Call the Laravel API to validate the token
    const response = await axios.post(
      `${import.meta.env.VITE_APP_API_URL}/auth/validate-token`,
      { token }
    );

    if (response.status === 200) {
      message.value = 'Login successful!';
      const customer = response.data.customer;
      const vehicle = response.data.vehicle;
      const vehicleHistory = response.data.vehicleHistory;
      const opcodes = response.data.opcodes;
      console.log(opcodes,response.data);

      // Save token and customer info
      localStorage.setItem('token', token); // Persist token
      store.commit('SET_CUSTOMER', customer); // Commit to Vuex store
      store.commit("SET_VEHICLE", vehicle);
      store.commit("SET_VEHICLE_HISTORY", vehicleHistory);
      store.commit("SET_VEHICLE_OPCODES", opcodes);
      // store.commit("CLEAR_CUSTOMER");
       // Update customerInfo reactively
      customerInfo.value = {
        ...customer,
        vehicle: vehicle,
        customerName: `${customer.name.first} ${customer.name.last}`, // Combine first and last name
      };
      // console.log(customerInfo.value.vehicle, vehicle);
      // console.log(customer,userInfo,customerInfo.value.vehicle);
      // Redirect to dashboard
      router.push('/');
      store.dispatch("handleSnackBar", response);
      // console.log(response.data.message);
    } else {
      message.value = response.data.message || 'Login failed.';
    }
  } catch (error) {
    // Handle API errors
    message.value =
      error.response?.data?.message || 'An error occurred while validating the token.';
      store.dispatch("handleSnackBar", error);
      throw error;
    // console.error("Validation error:", message.value);
  }
});
const displayName = computed(() => {
  // console.log(customerInfo.value?.customerName);
    return customerInfo.value?.customerName ;
  });
const vehicle = computed(() => {
  // console.log(customerInfo.value?.customerName);
    return customerInfo.value?.vehicle ;
  });
</script>

<style scoped>
/* Scoped styling can be customized if needed */
</style>
