import { createStore } from "vuex";
import VuexPersistence from "vuex-persist";
import apiRequest from "./../services/apiRequest";
import snackbar from "./modules/snackbar";
import auth from "./modules/auth";

// Configure Vuex Persistence
const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
    reducer: (state) => ({
        // persistedState: {
        //     // ...state.persistedState,
        //     customer: state.customer, // Persist customer data
        //     vehicle: state.vehicle, // Persist customer data
        //     vehicleHistory: state.vehicleHistory, // Persist customer data
        //     auth: state.auth, // Persist auth data
        // },
        customer: state.customer,
        vehicle: state.vehicle,
        vehicleHistory: state.vehicleHistory,
        opcodes: state.opcodes,
        auth: state.auth,
    }),
    filter: (mutation) => ["SET_CUSTOMER", "SET_VEHICLE", "SET_VEHICLE_HISTORY", "CLEAR_CUSTOMER", "SET_VEHICLE_OPCODES"].includes(
        mutation.type
    ), // Persist only relevant mutations

});

const store = {
    state: {
        customer: null, // Customer data
        vehicle: null, // Customer data
        vehicleHistory: null, // Customer data
        opcodes: null, // Customer data
    },
    getters: {

        customer: (state) => state.customer,
        vehicle: (state) => state.vehicle,
        vehicleHistory: (state) => state.vehicleHistory,
        opcodes: (state) => state.opcodes,
    },
    mutations: {
        /**
         * Set the customer data in the store.
         * @param {Object} state
         * @param {Object} customer
         */
        SET_CUSTOMER(state, customer) {
            state.customer = {...customer };
        },
        /**
         * Set the customer data in the store.
         * @param {Object} state
         * @param {Object} vehicle
         */
        SET_VEHICLE(state, vehicle) {
            state.vehicle = {...vehicle };
        },
        /**
         * Set the customer data in the store.
         * @param {Object} state
         * @param {Object} vehicleHistory
         */
        SET_VEHICLE_HISTORY(state, vehicleHistory) {
            state.vehicleHistory = [...vehicleHistory];
        },
        /**
         * Set the customer data in the store.
         * @param {Object} state
         * @param {Object} opcodes
         */
        SET_VEHICLE_OPCODES(state, opcodes) {
            state.opcodes = opcodes;
        },
        /**
         * Clear the customer data from the store.
         * @param {Object} state
         */
        CLEAR_CUSTOMER(state) {
            state.customer = null;
            state.vehicle = null;
            state.vehicleHistory = null;
            state.opcodes = null;
            localStorage.removeItem("token");
        },
    },
    actions: {
        /**
         * Display a snackbar message based on the API response.
         * @param {Object} context
         * @param {Object} response
         */
        handleSnackBar(context, response) {
            const message = response ?.data ?.message || response ?.response ?.data ?.message || "Something went wrong";
            const color = response ?.status === 200 ? "success" : "error";

            context.commit("set_snack_data", {
                message,
                color,
                snackbar: true,
            });
        },
        /**
         * Fetch data using a GET request.
         * @param {Object} context
         * @param {String} url
         * @returns {Promise}
         */
        async readData(context, url) {
            try {
                const response = await apiRequest.get(url);
                if (response.status === 200 || response.status === 202) {
                    return response;
                } else {
                    context.dispatch("handleSnackBar", response);
                    throw response;
                }
            } catch (error) {
                context.dispatch("handleSnackBar", error);
                throw error;
            }
        },
        /**
         * Fetch data using a POST request.
         * @param {Object} context
         * @param {Object} payload { url, data }
         * @returns {Promise}
         */
        async readPostData(context, payload) {
            try {
                const response = await apiRequest.post(payload.url, payload.data);
                if (response.status === 200) {
                    return response;
                } else {
                    context.dispatch("handleSnackBar", response);
                    throw response;
                }
            } catch (error) {
                context.dispatch("handleSnackBar", error);
                throw error;
            }
        },
        /**
         * Make a POST request for create/update/delete operations.
         * @param {Object} context
         * @param {Object} payload { url, data }
         * @returns {Promise}
         */
        async postData(context, payload) {
            try {
                const response = await apiRequest.post(payload.url, payload.data);
                if (response.status === 200 || response.status === 201) {
                    return response;
                } else {
                    context.dispatch("handleSnackBar", response);
                    throw response;
                }
            } catch (error) {
                context.dispatch("handleSnackBar", error);
                throw error;
            }
        },
        /**
         * Retrieve the latest user data on page refresh.
         * @param {Object} context
         * @returns {Promise}
         */
        async getUserData(context) {
            try {
                const response = await apiRequest.get("getUserData");
                if (response.status === 200) {
                    context.commit("SET_CUSTOMER", response.data.data ?.user);

                    return response;
                } else {
                    throw response;
                }
            } catch (error) {
                throw error;
            }
        },
    },
    plugins: [vuexLocal.plugin], // Enable persistence
    modules: {
        snackbar,
        auth,
    },
};

export default createStore(store);