const userInfo = {
    name: "",
    vehicle: {
        specification: {
            makeCode: "Toyota",
            make: "Toyota",
            modelCode: "Camry",
            model: "Camry",
            modelYear: 2019,
        },
        identification: {
            vin: "12356589789897",
        }
    },
    actions: [
        { text: "Customer Info", alt: "Customer Info", img: "/assets/images/home/customer-info.png", link: "/Customer/customer-info" },
        { text: "Video Walk Around", alt: "Video Walk Around", img: "/assets/images/home/video-walk-around.png", link: "/Customer/video-walkaround" },
        { text: "Vehicle History", alt: "Vehicle History", img: "/assets/images/home/vehicle-history.png", link: "/Customer/vehicle-history" },
        { text: "Upcoming Services", alt: "Upcoming Services", img: "/assets/images/home/upcoming-services.png", link: "/Customer/upcoming-services" },
        { text: "Contact Us", alt: "Contact Us", img: "/assets/images/home/contact-us.png", link: "/Customer/contact-us" },
    ],
    sidebar: [
        { text: "Customer Info", alt: "Customer Info", img: "/assets/images/sidebar/customer-info-side.png", link: "/Customer/customer-info" },
        { text: "Video Walk Around", alt: "Video Walk Around", img: "/assets/images/sidebar/video-walk-around-side.png", link: "/Customer/video-walkaround" },
        { text: "Vehicle History", alt: "Vehicle History", img: "/assets/images/sidebar/vehicle-history-side.png", link: "/Customer/vehicle-history" },
        { text: "Upcoming Services", alt: "Upcoming Services", img: "/assets/images/sidebar/upcoming-services-side.png", link: "/Customer/upcoming-services" },
        { text: "Contact Us", alt: "Contact Us", img: "/assets/images/sidebar/contact-us-side.png", link: "/Customer/contact-us" },
    ],
    navigateTo(actionText) {
        const routes = {
            "Customer Info": "/Customer/customer-info",
            "Video Walk Around": "/Customer/video-walkaround",
            "Vehicle History": "/Customer/vehicle-history",
            "Upcoming Services": "/Customer/upcoming-services",
            "Contact Us": "/Customer/contact-us",
        };

        const route = routes[actionText];
        if (route) {
            this.$router.push(route);
        } else {
            console.error(`Route not found for action: ${actionText}`);
        }
    },
};

export default userInfo;